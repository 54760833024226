import React, { useMemo } from 'react';
import { SessionClient, SessionUser } from '~/types/Session';

interface ContextData {
	user: SessionUser | null,
	client: SessionClient | null,
	hasSingleClient: boolean,
}

interface AuthProviderProps extends ContextData {
	children: React.ReactNode;
}

const AuthContext = React.createContext<ContextData | undefined>(undefined);

function AuthProvider({
	children, user, client, hasSingleClient,
}: AuthProviderProps) {
	const data = useMemo<ContextData>(() => ({ user, client, hasSingleClient }), [user, client, hasSingleClient]);
	return (
		<AuthContext.Provider value={data}>
			{children}
		</AuthContext.Provider>
	);
}

function useAuth() {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within a AuthProvider');
	}

	return context;
}

export { AuthProvider, useAuth };
